import { BooleanPopupParams } from '@morpho/dialog';
import { DynamicFormModalParams } from '@morpho/dynamic-form';
import { IssuerOverview } from 'apps/bankangle/src/app/core/patterns/issuer/issuer.model';
import { DealerOverview } from 'apps/bankangle/src/app/models/dealer';
import { TeamColleague } from '../../../core/elements/models/team-colleague.model';
import { DocumentInfo } from '../../../core/patterns/document-list/document-list.model';
import { DynamicActionParams } from '../../../shared/components/dynamic-action/dynamic-action.component';

export enum ActivityCardType {
  COMMENT = 'comment',
  FUTURE_EVENT = 'future_event',
  INFO = 'info',
  LIST = 'list',
  UPDATE = 'update',
  DOWNLOAD = 'download',
}

export type TradeActivity =
  | CommentActivity
  | FutureEventActivity
  | InfoActivity
  | ListActivity
  | DownloadActivity
  | UpdateActivity;

export interface CommentActivity extends BaseActivity {
  comment: string;
  suggestion?: any;
  field_label?: string;
  field_name?: string;
  type: ActivityCardType.COMMENT;
}

export interface FutureEventActivity extends BaseActivity {
  context_string?: string;
  secondary_datetime?: string;
  secondary_datetime_text?: string;
  info?: string;
  type: ActivityCardType.FUTURE_EVENT;
}

export interface InfoActivity extends BaseActivity {
  info?: string;
  message?: string;
  type: ActivityCardType.INFO;
}

export interface ListActivity extends BaseActivity {
  list?: string[];
  type: ActivityCardType.LIST;
}

export interface DownloadActivity extends BaseActivity {
  download?: {
    id?: number;
    name: string;
    document: string;
  }[];
  type: ActivityCardType.DOWNLOAD;
}

export interface UpdateActivity extends BaseActivity {
  updates?: {
    field_label: string;
    field_name: string;
    new_value: string | number | boolean;
    old_value: string | number | boolean | null;
    type?: string;
    element?: string;
  }[];
  type: ActivityCardType.UPDATE;
}

export interface BaseActivity {
  actor: {
    full_name: string;
    team_name: string;
    company_name?: string;
    company_type?: string;
  };
  datetime: string;
  square_logo: string;
  title: string;
}

export interface TradeDetails {
  id: number;
  issuer: IssuerOverview;
  issuing_entity: IssuerOverview;
  dealer: DealerOverview;
  dealer_entity: DealerOverview;
  formatted_size: string;
  tenor: string;
  structure: string;
  is_shared: boolean;
  product: string;
  documentation: string;
  funding_level: string;
  dealer_internal_id: string;
  currency: string;
  size: number;
  size_min: number;
  created_by: { first_name: string; last_name: string; id: number };
  last_modified: string;
  unread_activities: string;
  description: string;
  callable_structure: string;
  schedule: string;
  enquiry_type: string;
  tenor_label: string;
  is_trashed: boolean;
  is_test: boolean;
  legacy: boolean;
  stage: string;
  stage_key: string;
  maturity_date: string;
  is_tapped: boolean;
  is_benchmark: boolean;
  involved_dealer_users: [
    {
      id: number;
      email: string;
      full_name: string;
      issuer_employers: string[];
      bank_employers: string[];
      authorized_by_issuers: string[];
    },
  ];
  isin_code: string;
  can_instant_isin: boolean;
  has_final_terms_document: boolean;
  has_custom_termsheet: boolean;
}

export interface TradePermissions {
  // todo - how many of these are being used - can we remove any from api?
  can_announce_on_origin: boolean;
  can_change_swap_termsheet: boolean;
  can_comment: boolean;
  can_complete_stage: boolean;
  can_confirm_execution: boolean;
  can_edit_inputs: boolean;
  can_exclude_documents: boolean;
  can_grant_mandate: boolean;
  can_lock_inputs: boolean;
  can_populate_identifiers: boolean;
  can_post_trade_approve: boolean;
  can_reopen_previous_stage: boolean;
  can_request_isin: boolean;
  can_request_listing: boolean;
  can_request_reviews: boolean;
  can_resolve_outdated_templates: boolean;
  can_revoke_mandate: boolean;
  can_share_with_issuer: boolean;
  can_start_thread: boolean;
  can_take_ownership: boolean;
  can_termsheet_approve: boolean;
  can_unlock_inputs: boolean;
  can_update_dealer_working_group: boolean;
  can_view_current_stage_history: boolean;
  can_view_dealer_working_group: boolean;
  can_view_inputs: boolean;
  can_view_review_requests: boolean;
  can_add_additional_working_groups: boolean;
  can_stage_approve: boolean;
}

export interface TradeStateItem {
  icon: string;
  type: 'group_item';
  event: TradeStageEvent;
}

interface TradeStageEventActionExtras {
  external?: boolean;
  is_strong?: boolean;
  type?: 'text' | 'redirect';
  url?: string;
}

type TradeStageEventAction = Partial<DynamicFormModalParams & BooleanPopupParams & TradeStageEventActionExtras>;

export interface TradeStageEvent {
  action?: DynamicActionParams;
  actions?: TradeStageEventAction[];
  actors?: string[] | TradeActor[] | null;
  datetime?: string;
  description?: string;
  items?: TradeStateItem[];
  name?: string;
  type: 'event' | 'info' | 'note' | 'group' | 'review_request_list' | 'proceeded_to_next_stage';
}
export interface TradeStageShareEvent {
  reviewData?: TradeStageEventReviewData;
  reviewers: TradeTimelineReviewRequests;
  type: 'shared_with';
  users: TradeTimelineReviewRequests;
}
export interface TradeStageHeader {
  actors?: string[] | TradeActor[] | null;
  datetime?: string;
  description?: string;
  text?: string;
  type: 'event' | 'text';
}

export interface TradeActor {
  first_name: string;
  last_name: string;
  team: string;
}

export interface TradeStageEventReviewData {
  companies: TradeStageEventReviewDataCompany[];
  totalReviewCount: number;
  totalShareCount: number;
  totalPendingCount: number;
}

export interface TradeStageEventReviewDataCompany {
  company: string;
  isIssuer: boolean;
  pendingCount: number;
  reviewCount: number;
  reviewers: TradeTimelineReviewData[];
  shareCount: number;
}

export interface TradeStageStep {
  action?: DynamicActionParams;
  events?: (TradeStageEvent | TradeStageShareEvent)[];
  header?: TradeStageHeader[];
  items?: DocumentInfo[];
  logo?: string;
  modal_all_documents?: BooleanPopupParams | null;
  progress_status?: 'negative' | 'positive' | 'neutral';
  progress_text?: string;
  sequential: boolean;
  status: 'pending' | 'active' | 'completed' | 'blocked' | 'skipped' | 'disabled';
  title: string;
  tooltip_text?: string;
  type: 'activity' | 'list' | 'third_party_activity';
  url_all_documents?: string;
}

export interface TradeStage {
  actions: DynamicActionParams[];
  code?: 'post_trade_not_set_up';
  class?: string;
  documents?: DocumentInfo[];
  isCurrentStage?: boolean;
  modalAllDocuments?: BooleanPopupParams;
  optional: boolean;
  steps: TradeStageStep[];
  title: string;
  titleStage?: string;
  titleStatus?: string;
  type: 'stage';
  urlAllDocuments?: string;
}

export interface TradeWorkingGroup {
  id: number;
  trade: number;
  company: TradeWorkingGroupCompany;
  can_user_add: boolean;
  can_user_delete: boolean;
  users: TradeWorkingGroupUser[];
}

export interface TradeWorkingGroupUser {
  id: number;
  full_name: string;
  team_name: string;
  is_trade_owner: boolean;
}

export interface TradeWorkingGroupCompanyList {
  dealers: TradeWorkingGroupCompany[];
  law_firms: TradeWorkingGroupCompany[];
  issuers: TradeWorkingGroupCompany[];
}

export interface TradeWorkingGroupCompany {
  id: number;
  company_name: string;
  logo: string;
  short_name: string;
  square_logo: string;
  website: string;
  company_type_label: string;
  display_subtitle: string;
  display_title: string;
  legal_name: string;
}

export interface TradeWorkingGroupTeam {
  team_name: string;
  team_users: TeamColleague[];
  employer_name: string;
  employer_url: string;
}

export interface TradeReview {
  version: number;
  reviewer: {
    id: number;
    full_name: string;
    team_name_display: string;
  };
  reviewed: string | null;
}

export interface TradeReviewGroup {
  name: string;
  square_logo: string;
  review_requests: TradeReview[];
}

export interface TradeTimelineUser {
  first_name: string;
  last_name: string;
  team: string;
}

export interface TradeTimelineReview {
  requested_at?: string;
  reviewed?: string | null;
  reviewer?: TradeTimelineUser;
  shared_at?: string;
  status?: 'pending' | 'active' | 'completed' | 'blocked' | 'granted';
  type?: string;
  user?: TradeTimelineUser;
}
export interface TradeTimelineReviewData extends TradeTimelineReview {
  approved?: string;
}

export interface TradeTimelineShare {
  shared_at: string;
  user: TradeTimelineUser;
}

export interface TradeTimelineReviewRequests {
  dealers: {
    [company: string]: TradeTimelineReview[] | TradeTimelineShare[];
  };
  issuers: {
    [company: string]: TradeTimelineReview[] | TradeTimelineShare[];
  };
  law_firms: {
    [company: string]: TradeTimelineReview[] | TradeTimelineShare[];
  };
}
