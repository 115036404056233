<div class="content-container">
  @if (documentType === tradeDocumentType.Origin && url) {
    <a
      class="doc-name is-url"
      [routerLink]="url | routing: 'routerLink'"
      [queryParams]="url | routing: 'queryParams'"
      >{{ name }}</a
    >
  } @else {
    <span class="doc-name">{{ name }}</span>
    @if (documentType === tradeDocumentType.Loading && !uploadError) {
      <om-linear-progress-indicator [progress]="currentProgress.progress"></om-linear-progress-indicator>
    }
  }
  @switch (documentType) {
    @case (tradeDocumentType.Origin) {
      @if (lastUpdated) {
        <p class="doc-info">Last updated: {{ lastUpdated | dateToDisplayFormat: 'datetime' }}</p>
      }
    }
    @case (tradeDocumentType.Upload) {
      <p class="doc-info">
        Uploaded: {{ lastUpdated | dateToDisplayFormat: 'datetime' }} by
        {{ uploadedBy }}
      </p>
    }
    @case (tradeDocumentType.Loading) {
      @if (uploadError) {
        <p class="doc-info">
          {{ uploadError }}
        </p>
      }
    }
  }
</div>

<div class="action-container">
  @switch (documentType) {
    @case (tradeDocumentType.Origin) {
      <om-document-download-button [documentDownloadItem]="documentDownloadItem"></om-document-download-button>
    }
    @case (tradeDocumentType.Upload) {
      <a mat-icon-button [href]="uploadedDocumentDownloadUrl" [download]="name">
        <mat-icon svgIcon="file_download"></mat-icon>
      </a>
      <button mat-icon-button class="is-warning" (click)="deleteUploadedDoc(document.id)">
        <mat-icon svgIcon="remove"></mat-icon>
      </button>
    }
    @case (tradeDocumentType.Loading) {
      @if (uploadError) {
        <button mat-icon-button class="action is-warning" (click)="onDeleteError(name)">
          <mat-icon svgIcon="remove"></mat-icon>
        </button>
      }
    }
  }
</div>
